import * as Yup from 'yup';
const validationSchema = {
  Doctor: Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .required('First name is required!')
      .max(50, 'Maximum character length 50!'),
    lastName: Yup.string()
      .trim()
      .required('Last name is required!')
      .max(50, 'Maximum character length 50!'),
    businessName: Yup.string().trim().max(250, 'Maximum character length 250!'),
    address: Yup.string().trim().max(250, 'Maximum character length 250!'),
    cityState: Yup.string()
      .trim()
      .required('City is required!')
      .max(100, 'Maximum character length 100!'),
    country: Yup.string()
      .trim()
      .required('Country is required!')
      .max(100, 'Maximum character length 100!'),
    phone: Yup.string().trim().max(100, 'Maximum character length 100!'),
    website: Yup.string().trim().max(250, 'Maximum character length 250!'),
    field: Yup.string()
      .trim()
      .required('Specialty is required!')
      .max(250, 'Maximum character length 250!'),
  }),
  Hospital: Yup.object().shape({
    hospitalName: Yup.string()
      .trim()
      .required('Hospital name is required!')
      .max(250, 'Maximum character length 250!'),
    address: Yup.string().trim().max(250, 'Maximum character length 250!'),
    cityState: Yup.string()
      .trim()
      .required('City is required!')
      .max(100, 'Maximum character length 100!'),
    country: Yup.string()
      .trim()
      .required('Country is required!')
      .max(100, 'Maximum character length 100!'),
    phone: Yup.string().trim().max(100, 'Maximum character length 100!'),
    website: Yup.string().trim().max(250, 'Maximum character length 250!'),
    field: Yup.string()
      .trim()
      .required('Specialty is required!')
      .max(250, 'Maximum character length 250!'),
  }),
  Emergency: Yup.object().shape({
    countryName: Yup.string()
      .trim()
      .required('Country name is required!')
      .max(100, 'Maximum character length 100!'),
    categoryName: Yup.string()
      .trim()
      .required('Category name is required!')
      .max(100, 'Maximum character length 100!'),
    description: Yup.string().trim().max(250, 'Maximum character length 250!'),
    address: Yup.string().trim().max(250, 'Maximum character length 250!'),
    cityState: Yup.string().trim().max(100, 'Maximum character length 100!'),
    phone: Yup.string()
      .trim()
      .required('Phone is required!')
      .max(100, 'Maximum character length 100!'),
  }),
};
export default validationSchema;
