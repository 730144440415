import React from 'react';
import { useNavigate, useLocation, Link, Navigate } from 'react-router-dom';
import { useUpdateContactMutation } from '../features/apiSlice';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import validationSchema from '../utils/validationSchema';
import Loader from './loader';
import constants from '../utils/constants';

function EditContactForm() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { data } = state || {};
  const [updateContact, { isLoading, isSuccess, isError, error: updateError }] =
    useUpdateContactMutation();

  if (!data) {
    return <Navigate to='/doctor' />;
  }

  if (isSuccess) navigate('/contact');
  if (isLoading) return <Loader />;
  else
    return (
      <div className='flex'>
        <div className='container max-w-screen-lg mx-auto'>
          <div>
            <div className='bg-white rounded shadow-lg p-4 mb-2 md:rounded-b-2xl'>
              <nav className='flex m-2 pb-2 border-b' aria-label='Breadcrumb'>
                <ol className='inline-flex items-center space-x-1 md:space-x-3'>
                  <li className='inline-flex items-center'>
                    <Link
                      to='/contact'
                      className='inline-flex items-center text-sm font-medium text-primary hover:text-secondary'
                    >
                      <svg
                        className='w-4 h-4 mr-2'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z'></path>
                      </svg>
                      Emergency
                    </Link>
                  </li>
                  <li>
                    <div className='flex items-center'>
                      <svg
                        className='w-6 h-6 text-gray-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
                          clipRule='evenodd'
                        ></path>
                      </svg>
                      <span className='ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 '>
                        Edit
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
              <div className='grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3'>
                <div className='text-gray-600 '>
                  <p className='font-medium text-lg'> Emergency Contact Edit</p>
                  <p>Update field values to change data</p>
                </div>

                <div className='lg:col-span-2'>
                  {isError && (
                    <div
                      className='bg-red-100 rounded-lg py-5 px-6 mb-3 text-base text-red-700 inline-flex items-center w-full mt-2'
                      role='alert'
                    >
                      <svg
                        aria-hidden='true'
                        focusable='false'
                        className='w-4 h-4 mr-2 fill-current'
                        role='img'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 512 512'
                      >
                        <path
                          fill='currentColor'
                          d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'
                        ></path>
                      </svg>
                      <strong className='mr-1'>
                        {updateError.data
                          ? updateError.data.message
                          : constants.GlobalErrors.ServerError}
                      </strong>
                    </div>
                  )}
                  <Formik
                    initialValues={{
                      countryName: data.countryName,
                      categoryName: data.categoryName,
                      description: data.description,
                      address: data.address,
                      cityState: data.cityState,
                      phone: data.phone,
                      unlisted: data.unlisted,
                    }}
                    validationSchema={validationSchema.Emergency}
                    onSubmit={async (values) => {
                      updateContact({
                        emergencyId: data.emergencyId,
                        countryName: values.countryName.trim(),
                        categoryName: values.categoryName.trim(),
                        description: values.description.trim(),
                        address: values.address.trim(),
                        cityState: values.cityState.trim(),
                        phone: values.phone.trim(),
                        unlisted: JSON.parse(values.unlisted),
                      });
                    }}
                  >
                    <Form>
                      <div className='grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5'>
                        <div className='md:col-span-5'>
                          <label
                            htmlFor='countryName'
                            className='flex float-left font-medium text-gray-600 '
                          >
                            Country Name<span className='text-red-500 font-bold'> *</span>
                          </label>
                          <Field
                            id='countryName'
                            name='countryName'
                            maxLength='101'
                            className='h-10 border mt-1 rounded px-4 w-full bg-gray-50'
                          />
                          <ErrorMessage name='countryName'>
                            {(msg) => (
                              <div className='w-full h-4 font-medium text-red-500 flex float-left my-2'>
                                <span>
                                  <ExclamationTriangleIcon className='h-5 w-5 text-red-500 mr-1.5' />
                                </span>
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                        <div className='md:col-span-5'>
                          <label
                            htmlFor='categoryName'
                            className='flex float-left font-medium text-gray-600 '
                          >
                            Category Name<span className='text-red-500 font-bold'> *</span>
                          </label>
                          <Field
                            id='categoryName'
                            name='categoryName'
                            maxLength='101'
                            className='h-10 border mt-1 rounded px-4 w-full bg-gray-50'
                          />
                          <ErrorMessage name='categoryName'>
                            {(msg) => (
                              <div className='w-full h-4 font-medium text-red-500 flex float-left my-2'>
                                <span>
                                  <ExclamationTriangleIcon className='h-5 w-5 text-red-500 mr-1.5' />
                                </span>
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>

                        <div className='md:col-span-5'>
                          <label
                            htmlFor='phone'
                            className='flex float-left font-medium text-gray-600 '
                          >
                            Phone<span className='text-red-500 font-bold'> *</span>
                          </label>
                          <Field
                            id='phone'
                            name='phone'
                            maxLength='101'
                            className='h-10 border mt-1 rounded px-4 w-full bg-gray-50'
                          />
                          <ErrorMessage name='phone'>
                            {(msg) => (
                              <div className='w-full h-4 font-medium text-red-500 flex float-left my-2'>
                                <span>
                                  <ExclamationTriangleIcon className='h-5 w-5 text-red-500 mr-1.5' />
                                </span>
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                        <div className='md:col-span-5'>
                          <label
                            htmlFor='unlisted'
                            className='flex float-left font-medium text-gray-600 '
                          >
                            Status
                          </label>

                          <Field
                            as='select'
                            name='unlisted'
                            className='h-10 border mt-1 rounded px-4 w-full bg-gray-50'
                          >
                            <option value='true'>Unlisted</option>
                            <option value='false'>Listed</option>
                          </Field>
                        </div>
                        <div className='md:col-span-5'>
                          <label
                            htmlFor='address'
                            className='flex float-left font-medium text-gray-600 '
                          >
                            Address / Street
                          </label>
                          <Field
                            id='address'
                            name='address'
                            maxLength='251'
                            className='h-10 border mt-1 rounded px-4 w-full bg-gray-50'
                          />
                          <ErrorMessage name='address'>
                            {(msg) => (
                              <div className='w-full h-4 font-medium text-red-500 flex float-left my-2'>
                                <span>
                                  <ExclamationTriangleIcon className='h-5 w-5 text-red-500 mr-1.5' />
                                </span>
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>

                        <div className='md:col-span-5'>
                          <label
                            htmlFor='cityState'
                            className='flex float-left font-medium text-gray-600 '
                          >
                            City
                          </label>
                          <Field
                            id='cityState'
                            name='cityState'
                            maxLength='101'
                            className='h-10 border mt-1 rounded px-4 w-full bg-gray-50'
                          />
                          <ErrorMessage name='cityState'>
                            {(msg) => (
                              <div className='w-full h-4 font-medium text-red-500 flex float-left my-2'>
                                <span>
                                  <ExclamationTriangleIcon className='h-5 w-5 text-red-500 mr-1.5' />
                                </span>
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>

                        <div className='md:col-span-5'>
                          <label
                            htmlFor='description'
                            className='flex float-left font-medium text-gray-600 '
                          >
                            Description
                          </label>
                          <Field
                            as='textarea'
                            id='description'
                            name='description'
                            maxLength='251'
                            rows='3'
                            className='h-20 pt-2 border mt-1 rounded px-4 w-full bg-gray-50'
                          />
                          <ErrorMessage name='description'>
                            {(msg) => (
                              <div className='w-full h-4 font-medium text-red-500 flex float-left my-2'>
                                <span>
                                  <ExclamationTriangleIcon className='h-5 w-5 text-red-500 mr-1.5' />
                                </span>
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>

                        <div className='md:col-span-5 text-right'>
                          <div className='inline-flex items-end'>
                            <button
                              type='submit'
                              className='bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded'
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default EditContactForm;
