import React from 'react';
import Table from '../../components/DoctorTable';
import { useGetDoctorQuery } from '../../features/apiSlice';

function DoctorPage() {
  const defaultPageSize = 10;
  const column = [
    {
      Header: 'ID',
      accessor: 'doctorId',
    },
    {
      Header: 'First Name',
      accessor: 'firstName',
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
    },
    {
      Header: 'Business Name',
      accessor: 'businessName',
    },
    {
      Header: 'Address',
      accessor: 'address',
    },
    {
      Header: 'City',
      accessor: 'cityState',
    },
    {
      Header: 'Country',
      accessor: 'country',
    },
    {
      Header: 'Phone',
      accessor: 'phone',
    },
    {
      Header: 'Website',
      accessor: 'website',
    },
    {
      Header: 'Specialty',
      accessor: 'field',
    },
    {
      Header: 'Unlisted',
      accessor: 'unlisted',
    },
    {
      Header: 'Latitude',
      accessor: 'latitude',
    },
    {
      Header: 'Longitude',
      accessor: 'longitude',
    },
  ];
  return (
    <main>
      <div className='mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 bg-white shadow-lg md:shadow-gray-200 md:rounded-b-2xl'>
        <div className='px-4 py-6 sm:px-0 rounded-b-lg md:border-4  border-gray-200'>
          <Table column={column} useData={useGetDoctorQuery} defaultPageSize={defaultPageSize} />
        </div>
      </div>
    </main>
  );
}

export default DoctorPage;
