import React, { useEffect, useMemo, useState } from 'react';
import { useTable, usePagination } from 'react-table';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import Loader from './loader';
import ErrorPage from './ErrorPage';
import { Link } from 'react-router-dom';

function Table({ fileId, column, useData, defaultPageSize }) {
  const [noMoreResults, setNoMoreResults] = useState(false);
  const [results, setResults] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [jumpPageNo, setJumpPageNo] = useState(pageNo);
  const [data, setData] = useState([]);
  const [recordsPerPage, setRecordsPerPage] = useState(defaultPageSize);

  const {
    data: file,
    isLoading,
    isSuccess,
    isError,
  } = useData(
    { fileId: fileId, page: pageNo - 1, limit: recordsPerPage },
    {
      skip: noMoreResults,
    },
  );
  const columns = useMemo(() => column, [column]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: recordsPerPage,
        hiddenColumns: ['type', 'fileId'],
      },
      manualPagination: true,
    },
    usePagination,
  );

  useEffect(() => {
    if (file?.data?.length === 0) {
      setResults(false);
      setJumpPageNo(pageNo);
    } else if (file?.data?.length) {
      setData(file.data);
      setResults(true);
      setJumpPageNo(pageNo);
    } else if (page > 1) {
      setNoMoreResults(true);
    }
  }, [file, page, pageNo, setData, setNoMoreResults, setResults]);
  const handleJumpKeyDown = (event) => {
    if (event.key !== 'Backspace' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
      if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
      }
    }
  };

  const handleRecordsPerPage = (records) => {
    setPageNo(1);
    setJumpPageNo(1);
    setRecordsPerPage(records);
  };

  if (isLoading) return <Loader />;
  if (isSuccess) {
    return (
      <>
        <nav className='flex mx-3 my-2' aria-label='Breadcrumb'>
          <ol className='inline-flex items-center space-x-1 md:space-x-3'>
            <li className='inline-flex items-center'>
              <Link
                to='/file'
                className='inline-flex items-center text-sm font-medium text-primary hover:text-secondary'
              >
                <svg
                  className='w-4 h-4 mr-2'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z'></path>
                </svg>
                File
              </Link>
            </li>
            <li>
              <div className='flex items-center'>
                <svg
                  className='w-6 h-6 text-gray-400'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
                    clipRule='evenodd'
                  ></path>
                </svg>
                <span className='ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 '>
                  Report
                </span>
              </div>
            </li>
            <li aria-current='page'>
              <div className='flex items-center'>
                <svg
                  className='w-6 h-6 text-gray-400'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
                    clipRule='evenodd'
                  ></path>
                </svg>
                <span className='ml-1 text-sm font-medium text-gray-500 md:ml-2 '>
                  {file.file.name}
                </span>
              </div>
            </li>
          </ol>
        </nav>
        <div className='overflow-x-auto relative shadow-md sm:rounded-mb-lg'>
          {results ? (
            <table className='w-full  text-sm text-left text-gray-500 ' {...getTableProps()}>
              <thead className='text-xs text-gray-700 uppercase bg-gray-50 '>
                {headerGroups.map((headerGroup) => {
                  const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();

                  return (
                    <tr {...restHeaderGroupProps} key={key}>
                      {headerGroup.headers.map((column) => {
                        const { key, ...restColumn } = column.getHeaderProps();
                        return (
                          <th scope='col' className='py-3 px-3' {...restColumn} key={key}>
                            {column.render('Header')}
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}
              </thead>

              <tbody className='h-5' {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  const { key, ...restRowProps } = row.getRowProps();
                  return (
                    <tr className='bg-white border-b ' {...restRowProps} key={key}>
                      {row.cells.map((cell) => {
                        const { key, ...restCellProps } = cell.getCellProps();
                        return (
                          <>
                            <td className='py-4 px-3' key={key} {...restCellProps}>
                              <div className='break-normal'>{cell.render('Cell')}</div>
                            </td>
                          </>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className='m-4 p-4'>
              {pageNo === 1 ? <h1> No Issue Found</h1> : <h1> No Data Available</h1>}
            </div>
          )}
        </div>

        <div className='flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 mt-2'>
          <div className='flex flex-1 justify-between sm:hidden'>
            <button
              onClick={() => setPageNo(pageNo - 1)}
              disabled={pageNo === 1}
              className='relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 disabled:text-gray-300 hover:bg-gray-50'
            >
              Previous
            </button>
            <button
              onClick={() => setPageNo(pageNo + 1)}
              disabled={file.data.length === 0 || file.data.length < recordsPerPage}
              className='relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 disabled:text-gray-300 hover:bg-gray-50'
            >
              Next
            </button>
          </div>
          <div className='hidden sm:flex sm:flex-1 sm:items-center sm:justify-between'>
            <div className='flex flex-row mb-1 sm:mb-0'>
              <div className='relative'>
                <select
                  value={recordsPerPage}
                  onChange={(e) => handleRecordsPerPage(Number(e.target.value))}
                  className=' h-full rounded-l border block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                >
                  {[10, 20, 50, 100].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
                <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
                  <svg
                    className='fill-current h-4 w-4'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 20 20'
                  >
                    <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                  </svg>
                </div>
              </div>
            </div>
            <div className='flex flex-row mb-1 sm:mb-0'>
              <div className='relative'>
                <input
                  name='name'
                  onKeyDown={handleJumpKeyDown}
                  value={jumpPageNo}
                  maxLength='10'
                  autoComplete='off'
                  onChange={(e) => setJumpPageNo(e.target.value)}
                  className='w-14 px-2 rounded-r rounded-l  border border-gray-400 border-b block py-2  bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none'
                />
              </div>
              <button
                onClick={(e) => setPageNo(parseInt(jumpPageNo) || 1)}
                className='sm:mx-2 text-sm font-medium text-white  bg-primary hover:bg-secondary rounded-lg px-2.5 py-1 '
              >
                Go To Page
              </button>
            </div>
            <div>
              <nav
                className='isolate inline-flex -space-x-px rounded-md shadow-sm'
                aria-label='Pagination'
              >
                <button
                  onClick={() => setPageNo(pageNo - 1)}
                  disabled={pageNo === 1}
                  className='relative inline-flex items-center rounded-l-md border disabled:border-gray-300 border-primary bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20'
                >
                  <span className='sr-only'>Previous</span>
                  <ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
                </button>

                <button
                  onClick={() => setPageNo(pageNo + 1)}
                  disabled={file.data.length === 0 || file.data.length < recordsPerPage}
                  className='relative inline-flex items-center rounded-r-md border disabled:border-gray-300 border-primary bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20'
                >
                  <span className='sr-only'>Next</span>
                  <ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
                </button>
              </nav>
            </div>
          </div>
        </div>
      </>
    );
  }
  if (isError) {
    return <ErrorPage />;
  }
}

export default Table;
