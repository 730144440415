import { Outlet } from 'react-router-dom';
import Login from '../pages/Auth/login';

import { useIdToken } from 'react-firebase-hooks/auth';
import { auth } from '../features/config/firebase-config';
import Spinner from '../components/spinner';

import { useDispatch } from 'react-redux';
import { setToken } from '../features/authSlice';

const ProtectedRoutes = () => {
  const dispatch = useDispatch();
  const [user, loading] = useIdToken(auth);

  if (loading) return <Spinner />;

  const data = {
    user: user ? user.email : null,
  };
  dispatch(setToken(data));

  return user ? <Outlet /> : <Login />;
};

export default ProtectedRoutes;
