import React from 'react';

const Loader = () => {
  return (
    <div className='m-4 p-4 w-lg rounded border border-gray-200 shadow animate-pulse md:p-6 '>
      <div className='h-20 bg-gray-200 rounded-lg  w-full mb-6'></div>
      <div className='flex items-baseline mt-4 space-x-6'>
        <div className='w-4/5 h-72 bg-gray-200 rounded-lg '></div>
        <div className='w-1/5 h-72 bg-gray-200 rounded-lg '></div>
      </div>

      <span className='sr-only'>Loading...</span>
    </div>
  );
};

export default Loader;
