import logo from '../assets/logo.png';
import { Fragment } from 'react';
import { useNavigate, NavLink, useLocation } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, UserCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { auth } from '../features/config/firebase-config';
import { signOut } from 'firebase/auth';
import { useSelector } from 'react-redux';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();

  const user = useSelector((state) => state.auth.user);

  const navigation = [
    {
      name: 'Doctor',
      href: '/doctor',
      current: location.pathname.split('/').at(1).includes('doctor'),
    },
    {
      name: 'Hospital',
      href: '/hospital',
      current: location.pathname.split('/').at(1).includes('hospital'),
    },
    {
      name: 'Emergency',
      href: '/contact',
      current: location.pathname.split('/').at(1).includes('contact'),
    },
    { name: 'Files', href: '/file', current: location.pathname.split('/').at(1).includes('file') },
    { name: 'Users', href: '/user', current: location.pathname.split('/').at(1).includes('user') },
  ];

  const logout = () => {
    signOut(auth);
    navigate('/');
  };

  return (
    <Disclosure as='nav' className='bg-white  shadow-md shadow-gray-400 mb-0.5'>
      {({ open }) => (
        <>
          <div className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8'>
            <div className='relative flex h-16 items-center justify-between'>
              <div className='absolute inset-y-0 left-0 flex items-center sm:hidden'>
                {/* Mobile menu button*/}
                <Disclosure.Button className='inline-flex items-center justify-center rounded-md p-2 bg-primary  text-neutral-50 hover:bg-secondary hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                  <span className='sr-only'>Open main menu</span>
                  {open ? (
                    <XMarkIcon className='block h-6 w-6' aria-hidden='true' />
                  ) : (
                    <Bars3Icon className='block h-6 w-6' aria-hidden='true' />
                  )}
                </Disclosure.Button>
              </div>
              <div className='flex flex-1 items-center justify-center sm:items-stretch sm:justify-start'>
                <div className='flex flex-shrink-0 items-center'>
                  <img className='block h-8 w-auto lg:hidden' src={logo} alt='On Vacation Doctor' />
                  <img className='hidden h-8 w-auto lg:block' src={logo} alt='On Vacation Doctor' />
                </div>
                <div className='hidden sm:ml-6 sm:block'>
                  <div className='flex space-x-4'>
                    {navigation.map((item) => (
                      <NavLink
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.current
                            ? 'border-b-2 border-primary text-primary'
                            : 'text-slate-700 hover:bg-secondary hover:text-white',
                          'px-3 py-2 rounded-md text-sm font-medium',
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </NavLink>
                    ))}
                  </div>
                </div>
              </div>
              <div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
                {/* Profile dropdown */}
                <Menu as='div' className='relative ml-3'>
                  <div>
                    <Menu.Button className='flex rounded-full bg-transparent text-sm hover:text-secondary focus:outline-none focus:ring-1 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'>
                      <span className='sr-only'>Open user menu</span>

                      <UserCircleIcon className='h-6 w-6 ' />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items className='absolute right-0 z-10 mt-2 w-auto origin-top-right rounded-md  divide-y bg-white py-1  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'flex  px-4 py-2 text-sm text-gray-700 text-pointer flex-wrap',
                            )}
                          >
                            {user}
                          </div>
                        )}
                      </Menu.Item>

                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={logout}
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block text-center px-4 py-2 text-sm font-semibold text-red-500 cursor-pointer',
                            )}
                          >
                            Sign out
                          </div>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className='sm:hidden'>
            <div className='space-y-1 px-2 pt-2 pb-3'>
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as={NavLink}
                  to={item.href}
                  className={classNames(
                    item.current
                      ? 'bg-secondary text-white'
                      : 'text-slate-800 hover:bg-primary hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium',
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
