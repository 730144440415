import logo from '../../assets/logo.png';
import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from '../../features/config/firebase-config';
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import validate from '../../utils/validate.js';
import { errorMessage } from '../../utils/errorMessage.js';
import { onAuthStateChanged } from 'firebase/auth';
import Footer from '../../components/footer';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Login() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [signInWithEmailAndPassword, user, loading, error] = useSignInWithEmailAndPassword(auth);
  useEffect(() => {
    if (user) {
      navigate('/doctor');
    }
    onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate('/doctor');
      }
    });
  }, [user, navigate]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validate,
    onSubmit: (values) => {
      signInWithEmailAndPassword(values.email, values.password);
    },
  });

  return (
    <>
      <main>
        <section className='relative w-full h-full py-40 min-h-screen'>
          <section className='bg-white '>
            <div className='container px-6  mx-auto '>
              <div className='lg:flex'>
                <div className='mt-8 lg:w-1/2 items-center'>
                  <h1 className='text-gray-600  md:text-lg items-center'>
                    <span className='flex-shrink-0'>
                      <Link to='/'>
                        <img
                          className='h-20 w-20 inline mr-2'
                          src={logo}
                          alt='On Vacation Doctor'
                        />
                      </Link>
                      <h1 className='text-3xl md:text-4xl inline'>On Vacation Doctor</h1>
                    </span>
                  </h1>

                  <h1 className='mt-4 text-3xl font-medium text-gray-800 capitalize md:text-4xl lg:text-5xl '>
                    login
                  </h1>
                </div>

                <div className='mt-8 lg:w-1/2 bg-white shadow-lg shadow-gray-200 rounded-2xl p-4'>
                  {error ? (
                    <div
                      className='bg-red-100 rounded-lg py-4 px-6 mb-3 text-base text-red-700 inline-flex items-center w-full mt-2'
                      role='alert'
                    >
                      <svg
                        aria-hidden='true'
                        focusable='false'
                        className='w-4 h-4 mr-2 fill-current'
                        role='img'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 512 512'
                      >
                        <path
                          fill='currentColor'
                          d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'
                        ></path>
                      </svg>
                      <strong className='mr-1'>{errorMessage(error.code)}</strong>
                    </div>
                  ) : (
                    <div
                      className=' rounded-lg py-6 px-6 mb-3 text-base text-red-700 inline-flex items-center w-full mt-2'
                      role='alert'
                    ></div>
                  )}

                  <form className='w-full py-2' onSubmit={formik.handleSubmit}>
                    <div className='relative flex items-center'>
                      <span className='absolute'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          className='w-6 h-6 mx-3 text-gray-300 '
                          fill='none'
                          viewBox='0 0 24 24'
                          stroke='currentColor'
                          strokeWidth='2'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z'
                          />
                        </svg>
                      </span>

                      <input
                        type='email'
                        name='email'
                        maxLength='51'
                        className={classNames(
                          formik.errors.email
                            ? 'focus:border-red-600 focus:ring-red-700'
                            : 'focus:border-slate-600  focus:ring-slate-700',
                          'block w-full py-3 text-gray-700 bg-white border rounded-lg px-11  focus:outline-none focus:ring focus:ring-opacity-90',
                        )}
                        placeholder='Email address'
                        {...formik.getFieldProps('email')}
                      />
                    </div>

                    {formik.touched.email && formik.errors.email ? (
                      <span className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                        {formik.errors.email}
                      </span>
                    ) : null}

                    <div className='relative flex items-center mt-4'>
                      <span className='absolute'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          className='w-6 h-6 mx-3 text-gray-300 '
                          fill='none'
                          viewBox='0 0 24 24'
                          stroke='currentColor'
                          strokeWidth='2'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z'
                          />
                        </svg>
                      </span>

                      <input
                        type={showPassword ? 'text' : 'password'}
                        name='password'
                        className='block w-full pl-10 pr-20 py-3 text-gray-700 bg-white border rounded-lg focus:ring-zinc-900  focus:border-slate-600 focus:outline-none focus:ring focus:ring-opacity-100'
                        placeholder='Password'
                        {...formik.getFieldProps('password')}
                      />
                      <div
                        className='absolute flex right-4 mt-1.5 items-center h-full'
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='w-6 h-6 mx-3 text-gray-300  hover:text-neutral-900 cursor-pointer'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth='2'
                            stroke='currentColor'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z'
                            />
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth='1.5'
                            stroke='currentColor'
                            className='w-6 h-6 mx-3 text-gray-300  hover:text-neutral-900 cursor-pointer'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88'
                            />
                          </svg>
                        )}
                      </div>
                    </div>

                    {formik.touched.password && formik.errors.password && (
                      <span className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                        {formik.errors.password}
                      </span>
                    )}

                    <div className='w-full text-center mt-4'>
                      <button
                        type='submit'
                        disabled={loading}
                        className={classNames(
                          loading ? 'bg-sky-300' : 'bg-primary hover:bg-secondary',
                          'w-full px-6 py-3 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform  rounded-lg md:w-1/2  focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50',
                        )}
                      >
                        {loading ? 'Logging in' : 'Log in'}
                      </button>
                    </div>
                    <div className='w-full text-center'>
                      <Link
                        to='/reset'
                        className='inline-block mt-4 text-center text-sky-500 md:mt-0 md:mx-6 hover:underline'
                      >
                        Forgot your password?
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </section>
      </main>
    </>
  );
}

export default Login;
