import React from 'react';
import Table from '../../components/FileTable';
import { useGetFileQuery } from '../../features/apiSlice';

function FilePage() {
  const defaultPageSize = 10;
  const column = [
    {
      Header: 'ID',
      accessor: 'fileId',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Upload Time (UTC)',
      accessor: 'createdAt',
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Processed',
      accessor: 'processed',
    },
    {
      Header: 'Successful',
      accessor: 'successfull',
    },
    {
      Header: 'Duplicate',
      accessor: 'duplicate',
    },
  ];
  return (
    <main>
      <div className='mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 bg-white shadow-lg md:shadow-gray-200 md:rounded-b-2xl'>
        <div className='px-4 py-6 sm:px-0 rounded-b-lg md:border-4  border-gray-200'>
          <Table column={column} useData={useGetFileQuery} defaultPageSize={defaultPageSize} />
        </div>
      </div>
    </main>
  );
}

export default FilePage;
