import React from 'react';
import { useParams } from 'react-router-dom';
import Table from '../../components/FileDetailTable';
import { useGetFileDetailQuery } from '../../features/apiSlice';

function FileDetailPage() {
  let { fileId } = useParams();
  const defaultPageSize = 10;
  const column = [
    {
      Header: 'Unprocessed Row Number',
      accessor: 'rowNumber',
    },
    {
      Header: 'Failed Reasons',
      accessor: 'columnNames',
    },
  ];
  return (
    <main>
      <div className='mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 bg-white shadow-lg md:shadow-gray-200 md:rounded-b-2xl'>
        <div className='px-3 py-6 sm:px-0 rounded-b-lg md:border-4  border-gray-200'>
          <Table
            column={column}
            fileId={fileId}
            useData={useGetFileDetailQuery}
            defaultPageSize={defaultPageSize}
          />
        </div>
      </div>
    </main>
  );
}

export default FileDetailPage;
