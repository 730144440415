import { Dialog, Transition } from '@headlessui/react';
import { MapIcon } from '@heroicons/react/24/outline';
import { Fragment, useState } from 'react';
import Map from './map';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
function MapModal({ lat, lng }) {
  let [isOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <div
        className={classNames(
          lat && lng
            ? 'transform hover:text-primary hover:scale-110 cursor-pointer duration-300'
            : 'text-gray-300',
          'w-4 mr-2',
        )}
      >
        <MapIcon onClick={(lat && openModal) || null} />
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='fixed inset-0 z-10 overflow-y-auto' onClose={closeModal}>
          <div className='fixed inset-0 bg-black/30' aria-hidden='true' />
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0' />
            </Transition.Child>

            <span className='inline-block h-screen align-middle' aria-hidden='true'>
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block w-full max-w-lg p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-slate-50 shadow-2xl rounded-2xl'>
                <Dialog.Title>
                  <div className='flex pb-2 items-start justify-between  border-b rounded-t'>
                    <h3 className='text-lg font-medium text-gray-900 '>Map Location</h3>
                    <button
                      type='button'
                      className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center'
                      onClick={closeModal}
                    >
                      <svg
                        aria-hidden='true'
                        className='w-5 h-5'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                          clipRule='evenodd'
                        ></path>
                      </svg>
                      <span className='sr-only'>Close modal</span>
                    </button>
                  </div>
                </Dialog.Title>
                <div className='mt-2'>
                  <Map lat={lat} lng={lng} />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default MapModal;
