import React from 'react';

function Footer() {
  return (
    <footer className='fixed bottom-0 left-0 z-20 w-full p-4 bg-white border-t border-gray-200 shadow md:flex md:items-center md:justify-between md:p-6 '>
      <span className='text-sm text-gray-500 sm:text-center'>
        Copyright On Vacation Doctor © 2023 . All Rights Reserved.
      </span>
      <div className='flex flex-wrap items-center mt-3 text-sm text-gray-500  sm:mt-0'>
        <p className='mr-4 hover:underline md:mr-6 '>Version {process.env.REACT_APP_VERSION_N0}</p>
      </div>
    </footer>
  );
}

export default Footer;
