const columnName = {
  doctor: [
    'First Name',
    'Last Name',
    'Business Name',
    'Address',
    'City/State',
    'Country',
    'Phone',
    'Website',
    'Field',
  ],
  hospital: ['Hospital Name', 'Address', 'City/State', 'Country', 'Phone', 'Website', 'Field'],
  emergency: ['Country Name', 'Category Name', 'Address', 'City/State', 'Phone', 'Description'],
};
export default columnName;
