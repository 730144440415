import React, { memo } from 'react';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import Loader from './loader';
const Map = ({ lat, lng }) => {
  const center = {
    lat,
    lng,
  };
  const containerStyle = {
    width: '80vh',
    height: '40vh',
  };

  const key = process.env.REACT_APP_Google_Map_API_KEY;
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: key,
  });

  return isLoaded ? (
    <>
      <div className='flex items-center justify-center w-full'>
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={15}>
          <MarkerF position={center} />
        </GoogleMap>
      </div>
    </>
  ) : (
    <>
      <div className='w-4/5 h-2/5'>
        <Loader />
      </div>
    </>
  );
};

export default memo(Map);
