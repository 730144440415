import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import logo from '../../assets/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../../features/config/firebase-config';
import { useSendPasswordResetEmail } from 'react-firebase-hooks/auth';
import { resetErrorMessage } from '../../utils/errorMessage.js';
import { onAuthStateChanged } from 'firebase/auth';
import constants from '../../utils/constants';
import Footer from '../../components/footer';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function ResetPassword() {
  const navigate = useNavigate();
  const [sendPasswordResetEmail, sending, error] = useSendPasswordResetEmail(auth);
  const actionCodeSettings = {
    url: process.env.REACT_APP_REDIRECT_URL,
  };
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate('/doctor');
      }
    });
  }, [navigate]);

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = constants.AuthenticationErrors.RequiredMail;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = constants.AuthenticationErrors.InvalidMail;
    } else if (values.email.length > 50) {
      errors.email = constants.GlobalErrors.MaxReached;
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validate,
    onSubmit: async (values) => {
      const success = await sendPasswordResetEmail(values.email, actionCodeSettings);
      if (success) {
        alert('Reset mail has been sent !');
        navigate('/');
      }
    },
  });

  return (
    <>
      <main>
        <section className='relative w-full h-full py-40 min-h-screen'>
          <section className='bg-white '>
            <div className='container px-6  mx-auto '>
              <div className='lg:flex'>
                <div className='mt-8 lg:w-1/2 items-center'>
                  <h1 className='text-gray-600  md:text-lg'>
                    <span className='flex-shrink-0'>
                      <Link to='/'>
                        <img
                          className='h-20 w-20 inline mr-2'
                          src={logo}
                          alt='On Vacation Doctor'
                        />
                      </Link>
                      <h1 className='text-3xl md:text-4xl inline'>On Vacation Doctor</h1>
                    </span>
                  </h1>

                  <h1 className='mt-4 text-3xl font-medium text-gray-800 capitalize md:text-4xl lg:text-5xl '>
                    Reset Password
                  </h1>
                </div>

                <div className='mt-8 lg:w-1/2 lg:mt-0 bg-white shadow-lg shadow-gray-200 rounded-2xl p-4'>
                  {error ? (
                    <div
                      className='bg-red-100 rounded-lg py-4 px-6 mb-3 text-base text-red-700 inline-flex items-center w-full mt-2'
                      role='alert'
                    >
                      <svg
                        aria-hidden='true'
                        focusable='false'
                        className='w-4 h-4 mr-2 fill-current'
                        role='img'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 512 512'
                      >
                        <path
                          fill='currentColor'
                          d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'
                        ></path>
                      </svg>
                      <strong className='mr-1'>{resetErrorMessage(error.code)}</strong>
                    </div>
                  ) : (
                    <div
                      className=' rounded-lg py-6 px-6 mb-3 text-base text-red-700 inline-flex items-center w-full mt-2'
                      role='alert'
                    ></div>
                  )}
                  <form className='w-full  py-2' onSubmit={formik.handleSubmit}>
                    <div className='relative flex items-center'>
                      <span className='absolute'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          className='w-6 h-6 mx-3 text-gray-300 '
                          fill='none'
                          viewBox='0 0 24 24'
                          stroke='currentColor'
                          strokeWidth='2'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z'
                          />
                        </svg>
                      </span>

                      <input
                        type='email'
                        id='email'
                        name='email'
                        maxLength='51'
                        className={classNames(
                          formik.errors.email
                            ? 'focus:border-red-600 focus:ring-red-700'
                            : 'focus:border-slate-600  focus:ring-slate-700',
                          'block w-full py-3 text-gray-700 bg-white border rounded-lg px-11  focus:outline-none focus:ring focus:ring-opacity-90',
                        )}
                        placeholder='Email address'
                        {...formik.getFieldProps('email')}
                      />
                    </div>
                    {formik.touched.email && formik.errors.email ? (
                      <span className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                        {formik.errors.email}
                      </span>
                    ) : null}

                    <div className='mt-8 md:flex md:items-center md:space-x-2 space-y-2 md:space-y-0'>
                      <button
                        type='submit'
                        disabled={sending}
                        className={classNames(
                          sending ? 'bg-sky-300' : 'bg-primary hover:bg-secondary',
                          'w-full px-6 py-3 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform  rounded-lg md:w-1/2  focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50',
                        )}
                      >
                        {sending ? 'Resetting' : 'Reset'}
                      </button>
                      <button
                        type='button'
                        onClick={() => navigate('/')}
                        className='w-full px-6 py-3 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-red-500 rounded-lg md:w-1/2 hover:bg-red-600 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50'
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </section>
        <Footer />
      </main>
    </>
  );
}

export default ResetPassword;
