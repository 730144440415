import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/layout';
import DoctorPage from './pages/Doctor/doctor';
import HospitalPage from './pages/Hospital/hospital';
import ContactPage from './pages/Contact/contact';
import UserPage from './pages/User/user';
import EditDoctorData from './pages/Doctor/edit';
import EditHospitalData from './pages/Hospital/edit';
import EditContactData from './pages/Contact/edit';
import FilePage from './pages/File/file';
import FileDetailPage from './pages/File/detail';
function App() {
  return (
    <Routes>
      <Route path='/doctor' element={<Layout />}>
        <Route index element={<DoctorPage />} />
        <Route path='edit/:Id'>
          <Route index element={<EditDoctorData />} />
        </Route>
      </Route>
      <Route path='/hospital' element={<Layout />}>
        <Route index element={<HospitalPage />} />
        <Route path='edit/:hospitalId' element={<EditHospitalData />} />
      </Route>
      <Route path='/contact' element={<Layout />}>
        <Route index element={<ContactPage />} />
        <Route path='edit/:contactId' element={<EditContactData />} />
      </Route>
      <Route path='/file' element={<Layout />}>
        <Route index element={<FilePage />} />
        <Route path='detail/:fileId' element={<FileDetailPage />} />
      </Route>
      <Route path='/user' element={<Layout />}>
        <Route index element={<UserPage />} />
      </Route>
      <Route path='*' element={<Navigate to='/doctor' replace />} />
    </Routes>
  );
}

export default App;
