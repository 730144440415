import React from 'react';

function Spinner() {
  return (
    <div className='h-screen flex justify-center items-center gap-4'>
      <div className=' relative flex justify-center items-center h-20'>
        <div className='rounded animate-spin ease duration-300 w-5 h-5 border-2 border-blue-500'></div>
      </div>
      <div className=' relative flex justify-center items-center h-20'>
        <div className='rounded animate-spin ease duration-300 w-5 h-5 border-2 border-red-500'></div>
      </div>
      <div className=' relative flex justify-center items-center h-20'>
        <div className='rounded animate-spin ease duration-300 w-5 h-5 border-2 border-yellow-500'></div>
      </div>
    </div>
  );
}

export default Spinner;
