import React from 'react';
import EditContactForm from '../../components/EditContactForm';

function EditContactData() {
  return (
    <main>
      <div className='mx-auto max-w-7xl sm:px-6 lg:px-8'>
        <div className='px-4 sm:px-0'>
          <EditContactForm />
        </div>
      </div>
    </main>
  );
}

export default EditContactData;
