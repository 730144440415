import React from 'react';
import Table from '../../components/ContactTable';
import { useGetContactQuery } from '../../features/apiSlice';

function ContactPage() {
  const defaultPageSize = 10;
  const column = [
    {
      Header: 'ID',
      accessor: 'emergencyId',
    },
    {
      Header: 'Country Name',
      accessor: 'countryName',
    },
    {
      Header: 'Category Name',
      accessor: 'categoryName',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Address',
      accessor: 'address',
    },
    {
      Header: 'City',
      accessor: 'cityState',
    },
    {
      Header: 'Phone',
      accessor: 'phone',
    },
    {
      Header: 'Unlisted',
      accessor: 'unlisted',
    },
    {
      Header: 'Latitude',
      accessor: 'latitude',
    },
    {
      Header: 'Longitude',
      accessor: 'longitude',
    },
  ];
  return (
    <main>
      <div className='mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 bg-white shadow-lg md:shadow-gray-200 md:rounded-b-2xl'>
        <div className='px-4 py-6 sm:px-0 rounded-b-lg md:border-4  border-gray-200'>
          <Table column={column} useData={useGetContactQuery} defaultPageSize={defaultPageSize} />
        </div>
      </div>
    </main>
  );
}

export default ContactPage;
