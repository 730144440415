import React, { useEffect, useMemo, useState } from 'react';
import { useTable, usePagination } from 'react-table';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import {
  EnvelopeIcon,
  ExclamationTriangleIcon,
  LockClosedIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { useAddUserMutation, useDeleteUserMutation } from '../features/apiSlice';
import ErrorPage from './ErrorPage';
import Loader from './loader';
import constants from '../utils/constants';
import { userCreateErrorMessage, userDeleteErrorMessage } from '../utils/errorMessage';

function Table({ column, useData, defaultPageSize }) {
  const [noMoreResults, setNoMoreResults] = useState(false);
  const [results, setResults] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [jumpPageNo, setJumpPageNo] = useState(pageNo);
  const [data, setData] = useState([]);
  const [recordsPerPage, setRecordsPerPage] = useState(defaultPageSize);
  const [addUser, { isLoading: isAddLoading }] = useAddUserMutation();
  const [deleteUser, { isLoading: isDeleteLoading }] = useDeleteUserMutation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');

  const {
    data: user,
    isLoading,
    isFetching,
    isSuccess,
    isError,
  } = useData(
    { page: pageNo - 1, limit: recordsPerPage },
    {
      skip: noMoreResults,
    },
  );

  const columns = useMemo(() => column, [column]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: recordsPerPage,
        hiddenColumns: ['unlisted', 'adminId'],
      },
      manualPagination: true,
    },
    usePagination,
  );

  useEffect(() => {
    if (user?.length === 0) {
      setResults(false);
      setJumpPageNo(pageNo);
    } else if (user?.length) {
      setData(user);
      setResults(true);
      setJumpPageNo(pageNo);
    } else if (page > 1) {
      setNoMoreResults(true);
    }
  }, [user, page, pageNo, setData, setNoMoreResults, setResults]);

  const handleAddUser = () => {
    if (password.length < 6 || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email.trim())) {
      if (password.length < 6) setPasswordError(constants.GlobalErrors.MinRequired);
      else setPasswordError('');
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email.trim()))
        setEmailError(constants.AuthenticationErrors.InvalidMail);
      else setEmailError('');
    } else {
      const User = { email, password };
      addUser(User)
        .unwrap()
        .then(() => {
          setEmail('');
          setPassword('');
          setPasswordError('');
          setEmailError('');
        })
        .catch((error) => {
          setEmail('');
          setPassword('');
          setPasswordError('');
          setEmailError('');
          alert(userCreateErrorMessage(error.data.code));
        });
    }
  };
  const handleJumpKeyDown = (event) => {
    if (event.key !== 'Backspace' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
      if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
      }
    }
  };
  const handleRecordsPerPage = (records) => {
    setPageNo(1);
    setJumpPageNo(1);
    setRecordsPerPage(records);
  };

  const handleDelete = (Id) => {
    window.confirm(constants.Alerts.DeleteAlert) &&
      deleteUser(Id)
        .unwrap()
        .catch((error) => {
          alert(userDeleteErrorMessage(error.data.code));
        });
  };

  if (isLoading || isFetching || isDeleteLoading || isAddLoading) return <Loader />;
  if (isSuccess) {
    return (
      <>
        <div className='flex-col justify-between space-y-4 bg-white shadow-lg mb-2 md:shadow-gray-200 md:rounded-b-2xl p-2'>
          {email.length > 50 && (
            <div className='h-3 font-medium text-red-500 flex flex-row text-left items-center'>
              <span>
                <ExclamationTriangleIcon className='h-5 w-5  text-red-500 mr-1.5' />
              </span>
              {constants.GlobalErrors.MaxReached}
            </div>
          )}
          {emailError && (
            <div className='h-3 font-medium text-red-500 flex flex-row text-left items-center'>
              <span>
                <ExclamationTriangleIcon className='h-5 w-5  text-red-500 mr-1.5' />
              </span>
              {emailError}
            </div>
          )}
          {passwordError && (
            <div className='h-3 font-medium text-red-500 flex flex-row text-left items-center'>
              <span>
                <ExclamationTriangleIcon className='h-5 w-5  text-red-500 mr-1.5' />
              </span>
              {passwordError}
            </div>
          )}
          <div className='my-2 flex sm:flex-row flex-col '>
            <div className='block relative'>
              <span className='h-full absolute inset-y-0 left-0 flex items-center pl-2 '>
                <EnvelopeIcon className='h-4 w-4  text-gray-500' />
              </span>
              <input
                placeholder='Email'
                name='email'
                value={email}
                maxLength='51'
                onChange={(e) => setEmail(e.target.value)}
                className='w-full rounded-l border border-gray-400 border-b block pl-8 pr-8 py-2  bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none'
              />
            </div>
            <div className='block relative ml-0 mt-2 sm:mt-0 sm:ml-2'>
              <span className='h-full absolute inset-y-0 left-0 flex items-center pl-2 '>
                <LockClosedIcon className='h-4 w-4  text-gray-500' />
              </span>
              <input
                placeholder='Password'
                name='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className='w-full rounded-r sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-8 py-2  bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none'
              />
            </div>
            <div className='flex items-center mt-2 sm:mt-0'>
              <button
                onClick={handleAddUser}
                className='sm:mx-2   text-sm font-medium text-white  bg-primary disabled:bg-slate-400 hover:bg-secondary rounded-lg px-2.5 py-1 '
              >
                Add
              </button>
            </div>
          </div>
        </div>

        <div className='overflow-x-auto relative shadow-md sm:rounded-mb-lg'>
          {results ? (
            <table className='w-full  text-sm text-left text-gray-500 ' {...getTableProps()}>
              <thead className='text-xs text-gray-700 uppercase bg-gray-50 '>
                {headerGroups.map((headerGroup) => {
                  const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();

                  return (
                    <tr {...restHeaderGroupProps} key={key}>
                      {headerGroup.headers.map((column) => {
                        const { key, ...restColumn } = column.getHeaderProps();
                        return (
                          <th scope='col' className='py-3 px-3' {...restColumn} key={key}>
                            {column.render('Header')}
                          </th>
                        );
                      })}

                      <th scope='col' className='py-3 pl-6' key={key}>
                        Actions
                      </th>
                    </tr>
                  );
                })}
              </thead>

              <tbody className='h-5' {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  const { key, ...restRowProps } = row.getRowProps();
                  return (
                    <tr className='bg-white border-b ' {...restRowProps} key={key}>
                      {row.cells.map((cell) => {
                        const { key, ...restCellProps } = cell.getCellProps();
                        return (
                          <>
                            <td className='py-4 px-3' key={key} {...restCellProps}>
                              <div className='break-normal'>{cell.render('Cell')}</div>
                            </td>
                          </>
                        );
                      })}

                      <td className='py-3 px-6 bg-gray-50 lg:static sticky right-0' key={key}>
                        <div className='flex'>
                          <div className='w-4 mr-2 transform hover:text-primary hover:scale-110 cursor-pointer duration-300'>
                            {' '}
                            <TrashIcon onClick={() => handleDelete(row.values.adminId)} />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className='m-4 p-4'>
              {pageNo === 1 ? <h1> No Results Found</h1> : <h1> No Data Available</h1>}
            </div>
          )}
        </div>

        <div className='flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 mt-2'>
          <div className='flex flex-1 justify-between sm:hidden'>
            <button
              onClick={() => setPageNo(pageNo - 1)}
              disabled={pageNo === 1}
              className='relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 disabled:text-gray-300 hover:bg-gray-50'
            >
              Previous
            </button>
            <button
              onClick={() => setPageNo(pageNo + 1)}
              disabled={user?.length === 0 || user?.length < recordsPerPage}
              className='relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 disabled:text-gray-300 hover:bg-gray-50'
            >
              Next
            </button>
          </div>
          <div className='hidden sm:flex sm:flex-1 sm:items-center sm:justify-between'>
            <div className='flex flex-row mb-1 sm:mb-0'>
              <div className='relative'>
                <select
                  value={recordsPerPage}
                  onChange={(e) => handleRecordsPerPage(Number(e.target.value))}
                  className=' h-full rounded-l border block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                >
                  {[10, 20, 50, 100].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
                <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
                  <svg
                    className='fill-current h-4 w-4'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 20 20'
                  >
                    <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                  </svg>
                </div>
              </div>
            </div>
            <div className='flex flex-row mb-1 sm:mb-0'>
              <div className='relative'>
                <input
                  name='name'
                  onKeyDown={handleJumpKeyDown}
                  value={jumpPageNo}
                  maxLength='10'
                  autoComplete='off'
                  onChange={(e) => setJumpPageNo(e.target.value)}
                  className='w-14 px-2 rounded-r rounded-l  border border-gray-400 border-b block py-2  bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none'
                />
              </div>
              <button
                onClick={(e) => setPageNo(parseInt(jumpPageNo) || 1)}
                className='sm:mx-2 text-sm font-medium text-white  bg-primary hover:bg-secondary rounded-lg px-2.5 py-1 '
              >
                Go To Page
              </button>
            </div>
            <div>
              <nav
                className='isolate inline-flex -space-x-px rounded-md shadow-sm'
                aria-label='Pagination'
              >
                <button
                  onClick={() => setPageNo(pageNo - 1)}
                  disabled={pageNo === 1}
                  className='relative inline-flex items-center rounded-l-md border disabled:border-gray-300 border-primary bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20'
                >
                  <span className='sr-only'>Previous</span>
                  <ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
                </button>

                <button
                  onClick={() => setPageNo(pageNo + 1)}
                  disabled={user?.length === 0 || user?.length < recordsPerPage}
                  className='relative inline-flex items-center rounded-r-md border disabled:border-gray-300 border-primary bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20'
                >
                  <span className='sr-only'>Next</span>
                  <ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
                </button>
              </nav>
            </div>
          </div>
        </div>
      </>
    );
  }
  if (isError) {
    return <ErrorPage />;
  }
}

export default Table;
