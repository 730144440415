import React, { useEffect, useMemo, useState } from 'react';
import { useTable, usePagination, useRowSelect } from 'react-table';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useDeleteBulkHospitalMutation, useDeleteHospitalMutation } from '../features/apiSlice';
import { Link } from 'react-router-dom';
import UploadModal from './uploadModal';
import MapModal from './mapModal';
import IndeterminateCheckbox from './IndeterminateCheckbox';
import ErrorPage from './ErrorPage';
import Loader from './loader';
import constants from '../utils/constants';

function Table({ column, useData, defaultPageSize }) {
  const [noMoreResults, setNoMoreResults] = useState(false);
  const [results, setResults] = useState(true);
  const [filter, setFilter] = useState('');
  const [pageNo, setPageNo] = useState(1);
  const [jumpPageNo, setJumpPageNo] = useState(pageNo);
  const [data, setData] = useState([]);
  const [recordsPerPage, setRecordsPerPage] = useState(defaultPageSize);
  const [name, setName] = useState('');
  const [searchText, setSearchText] = useState('');
  const [deleteHospital, { isLoading: isDeleteLoading }] = useDeleteHospitalMutation();
  const [deleteBulkHospital, { isLoading: isBulkDeleteLoading }] = useDeleteBulkHospitalMutation();

  const {
    data: hospital,
    isLoading,
    isFetching,
    isSuccess,
    isError,
  } = useData(
    { page: pageNo - 1, limit: recordsPerPage, search: searchText.trim(), unlisted: filter },
    {
      skip: noMoreResults,
    },
  );

  const columns = useMemo(() => column, [column]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page, selectedFlatRows } =
    useTable(
      {
        columns,
        data,
        initialState: {
          pageIndex: 0,
          pageSize: recordsPerPage,
          hiddenColumns: ['unlisted', 'latitude', 'longitude'],
        },
        manualPagination: true,
      },
      usePagination,
      useRowSelect,
      (hooks) => {
        hooks.visibleColumns.push((columns) => [
          {
            id: 'selection',
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox
                  {...getToggleAllPageRowsSelectedProps()}
                  title='Select all records of current page'
                />
              </div>
            ),
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} title='Select record' />
              </div>
            ),
          },
          ...columns,
        ]);
      },
    );
  const selected = selectedFlatRows.map((d) => d?.original?.hospitalId);
  useEffect(() => {
    if (hospital?.length === 0) {
      setResults(false);
      setJumpPageNo(pageNo);
    } else if (hospital?.length) {
      setData(hospital);
      setResults(true);
      setJumpPageNo(pageNo);
    } else if (page > 1) {
      setNoMoreResults(true);
    }
  }, [hospital, page, pageNo, setData, setNoMoreResults, setResults]);
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setPageNo(1);
      setJumpPageNo(1);
      setSearchText(name);
    }
  };
  const handleJumpKeyDown = (event) => {
    if (event.key !== 'Backspace' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
      if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
      }
    }
  };
  const handleSearch = () => {
    setPageNo(1);
    setJumpPageNo(1);
    setSearchText(name);
  };
  const handleSearchClear = () => {
    setPageNo(1);
    setJumpPageNo(1);
    setSearchText('');
    setName('');
  };

  const handleRecordsPerPage = (records) => {
    setPageNo(1);
    setJumpPageNo(1);
    setRecordsPerPage(records);
  };

  const handleDelete = (Id) => {
    window.confirm(constants.Alerts.DeleteAlert) &&
      deleteHospital(Id)
        .unwrap()
        .catch((e) => {
          alert(constants.GlobalErrors.DeleteError);
        });
  };
  const handleBulkDelete = () => {
    const hospitals = {
      hospitals: selected,
    };
    window.confirm(constants.Alerts.BulkDeleteAlert) &&
      deleteBulkHospital(hospitals)
        .unwrap()
        .catch((e) => {
          alert(constants.GlobalErrors.DeleteError);
        });
  };

  if (isLoading || isFetching || isDeleteLoading || isBulkDeleteLoading) return <Loader />;
  if (isSuccess) {
    return (
      <>
        <div className='flex justify-between  bg-white shadow-lg mb-2 md:shadow-gray-200 md:rounded-b-2xl p-2'>
          <div className='my-2 flex sm:flex-row flex-col '>
            <div className='flex flex-row mb-1 sm:mb-0'>
              <div className='relative'>
                <select
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  className='h-full rounded-r border-t sm:rounded-r-none sm:border-r-0 border-l border-r border-b block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500'
                >
                  <option value=''>All</option>
                  <option value='true'>Unlisted</option>
                  <option value='false'>Listed</option>
                </select>
                <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
                  <svg
                    className='fill-current h-4 w-4'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 20 20'
                  >
                    <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                  </svg>
                </div>
              </div>
            </div>
            <div className='block relative'>
              <span className='h-full absolute inset-y-0 left-0 flex items-center pl-2 '>
                <svg viewBox='0 0 24 24' className='h-4 w-4 fill-current text-gray-500'>
                  <path d='M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z'></path>
                </svg>
              </span>
              <input
                placeholder='Search by Hospital Name'
                name='name'
                value={name}
                onChange={(e) => setName(e.target.value)}
                onKeyDown={handleKeyDown}
                className='w-full rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-8 py-2  bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none'
              />
            </div>
            <div className='flex items-center mt-2 sm:mt-0 lg:ml-0 sm:ml-2 ml-0'>
              <button
                onClick={handleSearch}
                className='md:mx-2   text-sm font-medium text-white  bg-primary hover:bg-secondary rounded-lg px-2.5 py-1 '
              >
                Search
              </button>
              <button
                onClick={handleSearchClear}
                className='text-sm font-medium text-primary  bg-white  hover:bg-neutral-300 rounded-lg px-2.5 py-1 '
              >
                Clear
              </button>
            </div>
          </div>
          <div className='my-2 flex sm:flex-row flex-col'>
            <UploadModal type='hospital' />
          </div>
        </div>
        {selected.length > 0 && results && (
          <div className='flex justify-between w-full p-2 bg-red-100'>
            <div className='px-2'> {selected.length} row(s) selected</div>
            <div>
              <button
                type='button'
                className='inline-flex justify-center px-4 py-2 text-sm text-white font-medium  bg-red-500 border border-transparent rounded-md hover:bg-red-700 duration-300'
                onClick={handleBulkDelete}
              >
                Delete
              </button>
            </div>
          </div>
        )}

        <div className='overflow-x-auto relative shadow-md sm:rounded-mb-lg'>
          {results ? (
            <table className='w-full  text-sm text-left text-gray-500 ' {...getTableProps()}>
              <thead className='text-xs text-gray-700 uppercase bg-gray-50 '>
                {headerGroups.map((headerGroup) => {
                  const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();

                  return (
                    <tr {...restHeaderGroupProps} key={key}>
                      {headerGroup.headers.map((column) => {
                        const { key, ...restColumn } = column.getHeaderProps();
                        return (
                          <th scope='col' className='py-3 px-3' {...restColumn} key={key}>
                            {column.render('Header')}
                          </th>
                        );
                      })}

                      <th scope='col' className='py-3 pl-6' key={key}>
                        Actions
                      </th>
                    </tr>
                  );
                })}
              </thead>

              <tbody className='h-5' {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  const { key, ...restRowProps } = row.getRowProps();
                  return (
                    <tr className='bg-white border-b ' {...restRowProps} key={key}>
                      {row.cells.map((cell) => {
                        const { key, ...restCellProps } = cell.getCellProps();
                        return (
                          <>
                            <td className='py-4 px-3' key={key} {...restCellProps}>
                              <div className='break-normal'>{cell.render('Cell')}</div>
                            </td>
                          </>
                        );
                      })}

                      <td className='py-3 px-6 bg-gray-50 lg:static sticky right-0' key={key}>
                        <div className='flex'>
                          <Link to={`edit/${row.values.hospitalId}`} state={{ data: row.values }}>
                            <div className='w-4 mr-2 transform hover:text-primary hover:scale-110'>
                              {' '}
                              <PencilSquareIcon />
                            </div>
                          </Link>
                          <div className='w-4 mr-2 transform hover:text-primary hover:scale-110 cursor-pointer duration-300'>
                            {' '}
                            <TrashIcon onClick={() => handleDelete(row.values.hospitalId)} />
                          </div>
                          <MapModal lat={row.values.latitude} lng={row.values.longitude} />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className='m-4 p-4'>
              {pageNo === 1 ? <h1> No Results Found</h1> : <h1> No Data Available</h1>}
            </div>
          )}
        </div>

        <div className='flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 mt-2'>
          <div className='flex flex-1 justify-between sm:hidden'>
            <button
              onClick={() => setPageNo(pageNo - 1)}
              disabled={pageNo === 1}
              className='relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 disabled:text-gray-300 hover:bg-gray-50'
            >
              Previous
            </button>
            <button
              onClick={() => setPageNo(pageNo + 1)}
              disabled={hospital?.length === 0 || hospital?.length < recordsPerPage}
              className='relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 disabled:text-gray-300 hover:bg-gray-50'
            >
              Next
            </button>
          </div>
          <div className='hidden sm:flex sm:flex-1 sm:items-center sm:justify-between'>
            <div className='flex flex-row mb-1 sm:mb-0'>
              <div className='relative'>
                <select
                  value={recordsPerPage}
                  onChange={(e) => handleRecordsPerPage(Number(e.target.value))}
                  className=' h-full rounded-l border block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                >
                  {[10, 20, 50, 100].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
                <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
                  <svg
                    className='fill-current h-4 w-4'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 20 20'
                  >
                    <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                  </svg>
                </div>
              </div>
            </div>
            <div className='flex flex-row mb-1 sm:mb-0'>
              <div className='relative'>
                <input
                  name='name'
                  onKeyDown={handleJumpKeyDown}
                  value={jumpPageNo}
                  maxLength='10'
                  autoComplete='off'
                  onChange={(e) => setJumpPageNo(e.target.value)}
                  className='w-14 px-2 rounded-r rounded-l  border border-gray-400 border-b block py-2  bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none'
                />
              </div>
              <button
                onClick={(e) => setPageNo(parseInt(jumpPageNo) || 1)}
                className='sm:mx-2 text-sm font-medium text-white  bg-primary hover:bg-secondary rounded-lg px-2.5 py-1 '
              >
                Go To Page
              </button>
            </div>
            <div>
              <nav
                className='isolate inline-flex -space-x-px rounded-md shadow-sm'
                aria-label='Pagination'
              >
                <button
                  onClick={() => setPageNo(pageNo - 1)}
                  disabled={pageNo === 1}
                  className='relative inline-flex items-center rounded-l-md border disabled:border-gray-300 border-primary bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20'
                >
                  <span className='sr-only'>Previous</span>
                  <ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
                </button>

                <button
                  onClick={() => setPageNo(pageNo + 1)}
                  disabled={hospital?.length === 0 || hospital?.length < recordsPerPage}
                  className='relative inline-flex items-center rounded-r-md border disabled:border-gray-300 border-primary bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20'
                >
                  <span className='sr-only'>Next</span>
                  <ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
                </button>
              </nav>
            </div>
          </div>
        </div>
      </>
    );
  }
  if (isError) {
    return <ErrorPage />;
  }
}

export default Table;
