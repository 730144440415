import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { auth } from './config/firebase-config';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URLS,
    prepareHeaders: async (headers) => {
      const token = await auth.currentUser.getIdToken();
      headers.set('authorization', token);
      return headers;
    },
  }),
  tagTypes: ['Doctor', 'Hospital', 'File', 'Contact', 'User'],
  endpoints: (builder) => ({
    getDoctor: builder.query({
      query: ({ page, limit, search, unlisted }) =>
        `doctor?page=${page}&limit=${limit}&name=${search}&unlisted=${unlisted}`,
      providesTags: ['Doctor'],
    }),
    getHospital: builder.query({
      query: ({ page, limit, search, unlisted }) =>
        `hospital?page=${page}&limit=${limit}&hospital=${search}&unlisted=${unlisted}`,
      providesTags: ['Hospital'],
    }),
    getContact: builder.query({
      query: ({ page, limit, search, unlisted }) =>
        `emergency?page=${page}&limit=${limit}&country=${search}&unlisted=${unlisted}`,
      providesTags: ['Contact'],
    }),
    getFile: builder.query({
      query: ({ page, limit, search, type }) =>
        `file?page=${page}&limit=${limit}&filename=${search}&type=${type}`,
      providesTags: ['File'],
    }),
    getFileDetail: builder.query({
      query: ({ fileId, page, limit }) => `file/${fileId}?page=${page}&limit=${limit}`,
      providesTags: ['File'],
    }),
    getUser: builder.query({
      query: ({ page, limit }) => `/?page=${page}&limit=${limit}`,
      providesTags: ['User'],
    }),
    addUser: builder.mutation({
      query: (User) => ({
        url: '/create',
        method: 'POST',
        body: User,
      }),
      invalidatesTags: ['User'],
    }),
    updateDoctor: builder.mutation({
      query: (Doctor) => ({
        url: `doctor/${Doctor.doctorId}`,
        method: 'PUT',
        body: Doctor,
      }),
      invalidatesTags: ['Doctor'],
    }),
    updateHospital: builder.mutation({
      query: (Hospital) => ({
        url: `/hospital/${Hospital.hospitalId}`,
        method: 'PUT',
        body: Hospital,
      }),
      invalidatesTags: ['Hospital'],
    }),
    updateContact: builder.mutation({
      query: (Contact) => ({
        url: `/emergency/${Contact.emergencyId}`,
        method: 'PUT',
        body: Contact,
      }),
      invalidatesTags: ['Contact'],
    }),
    deleteDoctor: builder.mutation({
      query: (doctorId) => ({
        url: `doctor/delete/${doctorId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Doctor'],
    }),
    deleteBulkDoctor: builder.mutation({
      query: (doctorId) => ({
        url: 'doctor/delete',
        method: 'DELETE',
        body: doctorId,
      }),
      invalidatesTags: ['Doctor'],
    }),
    deleteHospital: builder.mutation({
      query: (hospitalId) => ({
        url: `/hospital/delete/${hospitalId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Hospital'],
    }),

    deleteBulkHospital: builder.mutation({
      query: (hospitalId) => ({
        url: 'hospital/delete',
        method: 'DELETE',
        body: hospitalId,
      }),
      invalidatesTags: ['Hospital'],
    }),
    deleteContact: builder.mutation({
      query: (emergencyId) => ({
        url: `/emergency/delete/${emergencyId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Contact'],
    }),
    deleteBulkContact: builder.mutation({
      query: (emergencyId) => ({
        url: 'emergency/delete',
        method: 'DELETE',
        body: emergencyId,
      }),
      invalidatesTags: ['Contact'],
    }),
    deleteUser: builder.mutation({
      query: (adminId) => ({
        url: `/delete/${adminId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),
    deleteBulkUser: builder.mutation({
      query: (adminId) => ({
        url: '/delete',
        method: 'DELETE',
        body: adminId,
      }),
      invalidatesTags: ['User'],
    }),
    deleteFile: builder.mutation({
      query: (fileId) => ({
        url: `file/delete/${fileId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['File'],
    }),
    deleteBulkFile: builder.mutation({
      query: (fileId) => ({
        url: 'file/delete',
        method: 'DELETE',
        body: fileId,
      }),
      invalidatesTags: ['File'],
    }),
    uploadFile: builder.mutation({
      query: ({ File, type }) => ({
        url: `/file/upload?fileType=${type}`,
        method: 'POST',
        body: File,
      }),
      invalidatesTags: ['File'],
    }),
  }),
});

export const {
  useGetDoctorQuery,
  useGetHospitalQuery,
  useGetContactQuery,
  useGetUserQuery,
  useGetFileQuery,
  useGetFileDetailQuery,
  useAddUserMutation,
  useUpdateDoctorMutation,
  useUpdateHospitalMutation,
  useUpdateContactMutation,
  useDeleteDoctorMutation,
  useDeleteBulkDoctorMutation,
  useDeleteHospitalMutation,
  useDeleteBulkHospitalMutation,
  useDeleteContactMutation,
  useDeleteBulkContactMutation,
  useDeleteUserMutation,
  useDeleteBulkUserMutation,
  useDeleteFileMutation,
  useDeleteBulkFileMutation,
  useUploadFileMutation,
} = apiSlice;
