export function errorMessage(code) {
  switch (code) {
    case 'auth/user-not-found':
      return 'Invalid email or password';
    case 'auth/wrong-password':
      return 'Invalid email or password';
    default:
      return 'Something went wrong!';
  }
}
export function resetErrorMessage(code) {
  switch (code) {
    case 'auth/user-not-found':
      return 'Invalid email';
    default:
      return 'Something went wrong!';
  }
}

export function userDeleteErrorMessage(code) {
  switch (code) {
    case 'conflict':
      return 'Logged in user cannot be deleted!';
    default:
      return 'User delete failed!';
  }
}
export function userCreateErrorMessage(code) {
  switch (code) {
    case 'auth/email-already-in-use':
      return 'User with this email already exists!';
    default:
      return 'User creation failed!';
  }
}
