import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from './navbar';

function Layout() {
  return (
    <>
      <Navbar />
      <main className='App  bg-gray-50 min-h-screen pb-4'>
        <Outlet />
      </main>
    </>
  );
}

export default Layout;
