import React from 'react';
import Table from '../../components/UserTable';
import { useGetUserQuery } from '../../features/apiSlice';

function UserPage() {
  const defaultPageSize = 10;
  const column = [
    {
      Header: 'ID',
      accessor: 'adminId',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
  ];
  return (
    <main>
      <div className='mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 bg-white shadow-lg md:shadow-gray-200 md:rounded-b-2xl'>
        <div className='px-4 py-6 sm:px-0 rounded-b-lg md:border-4  border-gray-200'>
          <Table column={column} useData={useGetUserQuery} defaultPageSize={defaultPageSize} />
        </div>
      </div>
    </main>
  );
}

export default UserPage;
