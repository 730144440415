import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import columnName from '../utils/columnNames';

import { useUploadFileMutation } from '../features/apiSlice';
import Loader from './loader';
import constants from '../utils/constants';

const Upload = ({ type }) => {
  const navigate = useNavigate();
  const [uploadFile, { isLoading }] = useUploadFileMutation();
  const [fileName, setFileName] = useState('');
  const [csvFile, setCsvFile] = useState();
  const [error, setError] = useState('');
  const [fileType, setFileType] = useState('');
  const [checkFile, setCheckFile] = useState([]);
  const fileInputRef = useRef();

  useEffect(() => {
    if (type === constants.FileType.Doctor) {
      setFileType(constants.FileType.Doctor);
      setCheckFile(columnName.doctor);
    } else if (type === constants.FileType.Hospital) {
      setFileType(constants.FileType.Hospital);
      setCheckFile(columnName.hospital);
    } else if (type === constants.FileType.Emergency) {
      setFileType(constants.FileType.Emergency);
      setCheckFile(columnName.emergency);
    }
  }, [type, setFileType]);

  const handleClear = () => {
    setError('');
    fileInputRef.current.value = '';
    setFileName('');
    setCsvFile('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (fileName) {
      const file = csvFile;
      const reader = new FileReader();
      let validation;
      reader.onload = function (e) {
        const text = e.target.result;
        validation = processCSV(text);

        if (validation) {
          let formData = new FormData();
          formData.append('spreadsheet', file);

          uploadFile({ File: formData, type: fileType })
            .unwrap()
            .then(() => {
              navigate('/file');
            })
            .catch((error) => {
              alert(constants.GlobalErrors.UploadError);
            });
        } else {
          setError(constants.UploadErrors.InvalidColumn);
        }
      };

      reader.readAsText(file);
    } else {
      setError(constants.UploadErrors.NoFile);
    }
  };

  const processCSV = (str) => {
    const headers = str.slice(0, str.indexOf('\n')).split(constants.FileProcessing.delim);
    const header = str.slice(0, str.indexOf('\r')).split(constants.FileProcessing.delim);
    const res =
      (headers.length === checkFile.length &&
        headers.every((value, index) => value === checkFile[index])) ||
      (header.length === checkFile.length &&
        header.every((value, index) => value === checkFile[index]));
    return res;
  };

  const handleFileChange = (e) => {
    const name = e.target.files[0].name;
    if (name) {
      setFileName(name);
      if (/\.(csv)$/i.test(e.target.files[0].name)) {
        setError('');
        setCsvFile(e.target.files[0]);
      } else {
        setError(constants.UploadErrors.CsvOnly);
      }
    }
  };

  if (isLoading) return <Loader />;
  else
    return (
      <>
        {error && (
          <div
            className='bg-red-100 rounded-lg py-5 px-6 mb-3 text-base text-red-700 inline-flex items-center w-full mt-2'
            role='alert'
          >
            <svg
              aria-hidden='true'
              focusable='false'
              className='w-4 h-4 mr-2 fill-current'
              role='img'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 512 512'
            >
              <path
                fill='currentColor'
                d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'
              ></path>
            </svg>
            <strong className='mr-1'>{error} </strong>
            <button
              type='button'
              onClick={handleClear}
              className='ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 '
              aria-label='Close'
            >
              <span className='sr-only'>Close</span>
              <svg
                className='w-5 h-5'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                  clipRule='evenodd'
                ></path>
              </svg>
            </button>
          </div>
        )}
        <div className='flex items-center justify-center w-full '>
          <label
            htmlFor='dropzone-file'
            className='flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100 '
          >
            <div className='flex flex-col items-center justify-center pt-5 pb-6'>
              <svg
                aria-hidden='true'
                className='w-10 h-10 mb-3 text-gray-400'
                fill='none'
                stroke='currentColor'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12'
                ></path>
              </svg>
              {fileName !== '' ? (
                <p className='mb-2 text-sm text-gray-500  font-semibold'>{fileName}</p>
              ) : (
                <>
                  <p className='mb-2 text-sm text-gray-500 '>
                    <span className='font-semibold'>Click to upload</span>
                  </p>
                  <p className='text-xs text-gray-500 '> (CSV Files Only)</p>
                </>
              )}
            </div>
            <input
              id='dropzone-file'
              type='file'
              name='file'
              accept='.csv'
              onChange={handleFileChange}
              ref={fileInputRef}
              className='hidden'
            />
          </label>
        </div>
        <div className='flex flex-row-reverse pt-2'>
          <button
            onClick={(e) => handleSubmit(e)}
            disabled={error}
            className='text-white bg-primary hover:bg-secondary font-medium rounded-lg text-sm px-5 py-2  disabled:bg-slate-400 duration-300'
          >
            Upload
          </button>
        </div>
      </>
    );
};

export default Upload;
