const constants = {
  Alerts: {
    DeleteAlert: 'Delete this entry',
    BulkDeleteAlert: 'Delete selected entries',
  },
  FileProcessing: {
    delim: ',',
    status: 'Completed',
  },
  FileType: {
    Doctor: 'doctor',
    Hospital: 'hospital',
    Emergency: 'emergency',
  },
  AuthenticationErrors: {
    RequiredMail: 'Email address is required',
    InvalidMail: 'Invalid email address',
    RequiredPassword: 'Password is required',
  },
  GlobalErrors: {
    UploadError: 'Upload failed!',
    UserCreateError: 'User creation failed!',
    DeleteError: 'Delete failed!',
    ServerError: 'Something went wrong!',
    MaxReached: 'Maximum character length for email is 50!',
    MinRequired: 'Your password must be at least 6 characters long',
  },
  UploadErrors: {
    InvalidColumn: 'Invalid Column Name Formatting',
    NoFile: 'No file selected',
    CsvOnly: 'Select csv files only',
  },
};
export default constants;
