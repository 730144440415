import constants from './constants';

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = constants.AuthenticationErrors.RequiredMail;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = constants.AuthenticationErrors.InvalidMail;
  } else if (values.email.length > 50) {
    errors.email = constants.GlobalErrors.MaxReached;
  }

  if (!values.password) {
    errors.password = constants.AuthenticationErrors.RequiredPassword;
  }

  return errors;
};

export default validate;
